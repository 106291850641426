import ModalWrapper from "./ModalWrapper";
import {disableModals, enableMobileNumberCheckModal, updateDossier} from "../../../app/rootReducer";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import {PatchStepsEnum} from "../../constants/PatchStepsEnum";
import {useState} from "react";
import {setGeneralStep} from "../../../features/helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../constants/GeneralStepsEnum";
import {BeatLoader} from "react-spinners";
import {css} from "@emotion/react";

export const MobileCheckModal = () => {

    const {registrationLink} = useAppSelector(state => state.root);

    const {person} = useAppSelector((state: RootState) => state.form)
    const {greenCardCode} = useAppSelector((state) => state.vehicleInformation)
    const {damageDate} = useAppSelector((state) => state.vehicleInformation);

    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false)

    const override = css`
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    const formatMobileNumber = (mobileNumber: string): string => {
        let countryCode = mobileNumber.slice(0, 3);
        let withoutCountryCode = mobileNumber.slice(3);

        switch (countryCode) {
            case '+31':
                return `+31 (0) ${withoutCountryCode}`;
            case '+32':
                return `+32 (0) ${withoutCountryCode}`;
            default:
                return mobileNumber;
        }
    };

    const updateDossierData = async () => {
        setIsLoading(true);
        dispatch(enableMobileNumberCheckModal());
        try {
            await dispatch(updateDossier({
                data: {
                    person: {
                        first_name: person.first_name,
                        insertion: person.insertion,
                        last_name: person.last_name,
                        email: person.email,
                        mobile_number: person.mobile_number,
                        address: {
                            zip_code: person.address.zip_code,
                            house_number: person.address.house_number,
                            house_number_addition: person.address.house_number_addition,
                            street: person.address.street,
                            city: person.address.city,
                            country: person.address.country,
                        }
                    },
                },
                method: PatchStepsEnum.PatchPerson,
            }));

            if (greenCardCode) {
                await dispatch(updateDossier({
                    data: {
                        green_card_code: greenCardCode,
                    },
                    method: PatchStepsEnum.PatchGreenCardCode
                }));
            }

            await dispatch(updateDossier({
                data: {
                    damage_date: damageDate,
                },
                method: PatchStepsEnum.PatchDamageDate
            }));

            dispatch(disableModals());
            dispatch(setGeneralStep(GeneralStepsEnum.EnableResume));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ModalWrapper>
            {!isLoading ? (
                <>
                    <p style={{marginBottom: "2rem"}}>Zie hieronder hoe we jouw <b>mobiele</b> telefoonnummer zullen opslaan in ons systeem, klopt dit?
                        Jouw mobiele telefoonnummer is belangrijk voor ons,
                        zonder geldig telefoonnummer kunnen we jouw aanvraag
                        niet in behandeling nemen.
                    </p>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        updateDossierData();
                    }}>
                        <h4>{formatMobileNumber(person.mobile_number_input)}</h4>
                        <div className="c-modal__buttons">
                            <button className={'btn btn--gray'} onClick={() => dispatch(disableModals())}
                                    type={'button'}>
                                <span>Nee, dit klopt niet</span>
                            </button>
                            <button className={'btn btn--icon background-cta'} type={'submit'}><span>Ja, dit klopt</span>
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <BeatLoader css={override} color={registrationLink.primary_color}/>
        )}
        </ModalWrapper>
    )
}
